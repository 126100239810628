/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */



/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'tailwindcss/utilities';


// Swiper
// global.scss
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';
@import 'swiper/css/zoom';





@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl Book';
  src: url('./assets/fonts/Stolzl/Stolzl-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./assets/fonts/Stolzl/Stolzl-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito';
  src: url('./assets/fonts/Nunito/Nunito-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}



.transparent-modal {
  --background: rgba(44,39,45,0.2);
  --height: 100%;
  --width: 100%;

  &::part(content) {
    backdrop-filter: blur(12px);
  }
}


// header
ion-header.margin-top {
  margin-top: 26px;
}


.action-sheet-group .action-sheet-button {
  color: var(--ion-color-secondary);
}

.action-sheet-group .action-sheet-button:hover {
  color: var(--ion-color-secondary-tint);
}

